import React from 'react';
import 'styles/Header.css'; // Import CSS file for header styling

const Header = () => {
  return (
    <div className="Header Container header-container">
      <div className="header-content">
        <div className='header-title pb-5 text-xl whitespace-4'>Welcome to</div>
        <div className="header-description text-2xl font-mplus font-medium">A curated list of the tech I <span className='text-orange-500'>actually</span> use</div>
      </div>
    </div>
  );
};

export default Header;
