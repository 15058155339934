import React, { useState }  from 'react';

const Nav = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark'); // Toggle 'dark' class on <html> element
  };

  return (

  <div className={`min-h-full ${isDarkMode ? 'dark' : ''} `}>
    <nav className="bg-hide-800 dark:bg-gray-800 dark:text-zinc-50 bg-gradient-to-b from-orange-50 to-green-50  dark:from-gray-800 dark:to-slate-700">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="Link Container flex h-16 items-center justify-between ">
          <div className="flex items-center">
            <div className="flex-shrink-0 ">
              {!isDarkMode ?
              <img className="h-10 w-10 " src="icon-light.svg" alt="Long Bui's"></img>
                :
              <img className="h-10 w-10  " src="icon-dark.svg" alt="Long Bui's"></img>
            } 
              </div>
            <div className="md:block">
              <div className="m-2 flex items-start space-2 text-center items-center">
                <a href="https://uses.longdatadevlog.com" className="hover:bg-gray-700 hover:text-white rounded-md px-2 py-2 text-sm font-xl">Long Bui - LongDataDevLog</a>
                <a href="https://longdatadevlog.com" className="hover:bg-gray-700 hover:text-white rounded-md px-2 py-2 text-sm font-xl text-center">About me</a>
                {/* <a href="https://payhip.com/longdatadevlog" className="hover:bg-gray-700 hover:text-white rounded-md px-2 py-2 text-sm font-xl text-left">Digital Products</a> */}
                {/* <a href="https://github.com/longbuivan/uses" className="hover:bg-gray-700 hover:text-white rounded-md px-2 py-2 text-sm font-xl flex direction-contents items-center">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C132.3 32 32 134.9 32 261.7c0 101.5 64.2 187.5 153.2 217.9a17.56 17.56 0 003.8.4c8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1a102.4 102.4 0 01-22.6 2.7c-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.6-24.9-33.6-19.5-13.7-.1-14.1 1.4-14.1h.1c22.5 2 34.3 23.8 34.3 23.8 11.2 19.6 26.2 25.1 39.6 25.1a63 63 0 0025.6-6c2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8a18.64 18.64 0 015-.5c8.1 0 26.4 3.1 56.6 24.1a208.21 208.21 0 01112.2 0c30.2-21 48.5-24.1 56.6-24.1a18.64 18.64 0 015 .5c12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5a19.35 19.35 0 004-.4C415.9 449.2 480 363.1 480 261.7 480 134.9 379.7 32 256 32z"></path></svg>
                Source</a> */}
              </div>
            </div>
          </div>
        <div className="Dark Mode Container flex h-16 items-center">
          <div className="flex items-left justify-center">
                <button
                  className="theme-toggle flex items-center justify-center rounded-full w-10 h-10 bg-transparent border border-gray-800 dark:border-gray-400 dark:bg-gray-400 dark:text-white focus:outline-none"
                  onClick={toggleDarkMode}
                  type="button"
                  title="Toggle theme"
                  aria-label="Toggle theme"
                >
                {!isDarkMode ? 
                  <svg className="w-8 h-8 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      // className="border border-gray-400"
                      d="M12 3V4M12 20V21M4 12H3M6.31412 6.31412L5.5 5.5M17.6859 6.31412L18.5 5.5M6.31412 17.69L5.5 18.5001M17.6859 17.69L18.5 18.5001M21 12H20M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
                      // stroke="#000000"
                      border-background="white"
                      strokeWidth="2"
                      fillOpacity="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg> : 
                  <svg  className="w-8 h-8 stroke-current" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        class="stroke-width-1 fill-gray-800"
                        d="m32.8,29.3c-8.9-.8-16.2-7.8-17.5-16.6-.3-1.8-.3-3.7,0-5.4.2-1.4-1.4-2.3-2.5-1.6C6.3,9.7,2.1,16.9,2.5,25c.5,10.7,9,19.5,19.7,20.4,10.6.9,19.8-6,22.5-15.6.4-1.4-1-2.6-2.3-2-2.9,1.3-6.1,1.8-9.6,1.5Z" />
                  </svg>
                  }
                </button>
          </div>
        </div>
        </div>
      </div>
    </nav>
  </div>
  );
};

export default Nav;
