import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";

const App = () => {
  const [products, setProducts] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const context = require.context("../../public/posts/", false, /\.md$/);
        const files = context.keys();

        const productsData = await Promise.all(
          files.map(async (file) => {
            const contentResponse = await fetch(`/posts/${file}`);
            const content = await contentResponse.text();

            const regex = /^---\n(.*?)\n---\n(.*)$/s;
            const match = content.match(regex);

            if (match) {
              const frontmatter = match[1];
              const data = frontmatter.split("\n").reduce((result, line) => {
                const [key, value] = line.split(":").map((s) => s.trim());
                if (key && value) {
                  result[key] = value;
                }
                return result;
              }, {});

              const product = {
                slug: data.slug || "",
                title: data.title || "",
                description: data.description || "",
                buy: 'https://'+data.buy || "", 
                content: match[2],
              };
              
              return product;
            } else {
              console.error("Frontmatter not found in file:", file);
              return null;
            }
          })
        );

        setProducts(productsData.filter((product) => product !== null));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark'); // Toggle 'dark' class on <html> element
  };


  return (
    <div className={`App container min-h-screen mx-auto p-0 bg-orange-50 text-gray ${isDarkMode ? 'dark' : ''} dark:bg-gray-800 dark:text-zinc-50 break-words leading-6 transition-colors duration-500`}>
      <Nav  
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
      />

      <Header />
      <div className="home-description p-2">
        <h1 className="text-3xl font-bold text-center font-mono">
          What I Use as a Data Engineer
        </h1>
        <p className="text-base italic font-i font-mono">
          I've been spending hours and hours in front of computer
          every day. So, I've been continuously improving\ the workspace in order
          to boost the productivity. Most of the links are Affiliate links, so I'll get filthy rich if you click them and buy
          something.
          <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="http://blogs.longdatadevlog.com">To know more About me
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="inline" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144m124-144H100"></path></svg>
          </a>
          <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="http://youtube.com/@longdatadevlog">Youtube Channel
          </a>
        </p>
        <div class="Digital Products ">
          <div class="text-base font-bold font-i mt-5 font-mono text-xl text-base-800">Section 1: Digital Products - Improve knowledge base</div>
          <a className="inline-flex items-center gap-1 indent-1 text-orange-500" href="https://payhip.com/longdatadevlog">
          <img class="border border-slate-300 dark:border-zinc-700 rounded-xl" alt="Digital Products banner" width="1440" height="720" src="Digital-Products.png" loading="lazy" decoding="async"></img>
          </a>
        </div>

      </div>
      <div class="text-base font-bold font-i font-mono text-xl text-base-800 p-2">Section 2: Affiliate Products - Improve productivity</div>
      <div className="grid p-2 grid-cols-1 md:grid-cols-2 gap-8">
        {products.map((product, index) => (
          <div key={index} className="border p-4 rounded-md px-5 shadow-md px-2">
            <h2 className="text-lg font-semibold mb-2">{product.title}</h2>
            <p className="mb-2 italic">{product.description}</p>
            <a href={product.buy} className="link mt-4 indent-4 text-cyan-700" target="_blank" rel="noopener noreferrer">
            Lazada link
            </a>
            <div className="mt-4 indent-4 rounded-xl">
              <ReactMarkdown>{product.content}</ReactMarkdown>
            </div>
          </div>
        ))}
      </div>

      <Footer />

    </div>
  );
};

export default App;
